import { createContext, useReducer, useEffect } from "react";
import Reducer from "./Reducer";
import axios from "axios";

const INITIAL_STATE = {
  // user:
  //   localStorage.getItem("user") !== "undefined"
  //     ? JSON.parse(localStorage.getItem("user"))
  //     : null,
  user: null,
  isAuthenticated: false,
  isFetching: false,
  error: false,
};

export const Context = createContext({
  ...INITIAL_STATE,
});

export const ContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, INITIAL_STATE);

  // const getUserInfo = async () => {
  //   const token =
  //     localStorage.getItem("token") !== "undefined"
  //       ? localStorage.getItem("token")
  //       : null;

  //   if (token) {
  //     axios.defaults.headers.common["x-auth-token"] = token;
  //     try {
  //       // ***DEV/PROD***
  //       let res;
  //       if (process.env.REACT_APP_MODE === "DEV") {
  //         res = await axios.get("/auth/info");
  //       } else if (process.env.REACT_APP_MODE === "PROD") {
  //         res = await axios.get("/api/auth/info");
  //       }

  //       dispatch({
  //         type: "LOGIN_SUCCESS",
  //         payload: {
  //           ...res.data.user,
  //         },
  //       });
  //     } catch (err) {
  //       console.error(err);
  //     }
  //   } else {
  //     delete axios.defaults.headers.common["x-auth-token"];
  //   }
  // };

  const getUserInfo = async () => {
    const token = localStorage.getItem("token");

    if (token) {
      axios.defaults.headers.common["x-auth-token"] = token;
      try {
        let res;
        if (process.env.REACT_APP_MODE === "DEV") {
          res = await axios.get("/auth/info");
        } else if (process.env.REACT_APP_MODE === "PROD") {
          res = await axios.get("/api/auth/info");
        }

        dispatch({
          type: "LOGIN_SUCCESS",
          payload: {
            ...res.data.user,
          },
        });
      } catch (err) {
        console.error(err);
        localStorage.removeItem("token");
      }
    } else {
      delete axios.defaults.headers.common["x-auth-token"];
    }
  };

  useEffect(() => {
    const checkUser = async () => {
      if (!state.user) {
        await getUserInfo();
      }
    };
    checkUser();
  }, [state.user]);

  return (
    <Context.Provider
      value={{
        ...state,
        getUserInfo,
        dispatch,
      }}
    >
      {children}
    </Context.Provider>
  );
};
