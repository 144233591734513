import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import style from "./CookiesDisclaimer.module.scss";
import Cookies from "js-cookie";

const CookiesDisclaimer = () => {
  const [isVisible, setIsVisible] = useState(!Cookies.get("cookies_accepted"));

  useEffect(() => {
    setIsVisible(!Cookies.get("cookies_accepted"));
  }, []);

  const handleOk = () => {
    Cookies.set("cookies_accepted", "true", { expires: 7 });
    setIsVisible(false);
  };

  return (
    <div
      className={`${style.cookiesDisclaimerWrapper} ${
        isVisible ? style.visible : ""
      }`}
    >
      <div className={style.cookiesDisclaimer}>
        <p>
          🍪 Сайт использует{" "}
          <Link to="cookies">
            <b>cookies</b>
          </Link>
          . Продолжая пользоваться сайтом, Вы соглашаетесь с их использованием.
        </p>
        <button onClick={handleOk} className={style.accept}>
          Принять
        </button>
      </div>
    </div>
  );
};

export default CookiesDisclaimer;
