import React, { useState, useEffect } from "react";
import style from "./ScrollToTopButton.module.scss";
import { ReactComponent as ToTopSVG } from "../../assets/imgs/toTop.svg";

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 400) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <div className={style.toTop}>
      {isVisible ? (
        <div onClick={scrollToTop}>
          <ToTopSVG />
        </div>
      ) : null}
    </div>
  );
};

export default ScrollToTopButton;
