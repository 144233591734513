import { Navigate } from "react-router-dom";
// import useAuth from "../../assets/hooks/useAuth";

// const GuestGuard = ({ children }) => {
//   const { isAuthenticated } = useAuth();

//   if (!isAuthenticated) {
//     return <Navigate to="/login" />;
//   }
//   return <>{children}</>;
// };

const GuestGuard = ({ children }) => {
  const token = localStorage.getItem("token");

  if (!token) {
    return <Navigate to="/login" />;
  }

  return <>{children}</>;
};

export default GuestGuard;
